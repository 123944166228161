// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__form>*+* {
    margin-top: 0.625rem;
}

.modal__form__group {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.modal__form__label {
    margin-bottom: 0.25rem;
    font-size: 0.875rem;
    font-family: InterMedium;
}

.modal__form__input {
    padding: 0.25rem 0.75rem;
    border: var(--border-radius-lg) solid var(--border-clr);
    border-radius:
        /*0.375rem*/
        4px;
    font-size: 1rem;
    line-height: 1.5;
    color: #4a5568;
    background-color: #fff;
    background-clip: padding-box;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.modal__form__input:hover,
.modal__form__input:focus {
    border: 1px solid var(--border-clr-hover);
}

.modal__form__input:focus-visible {
    outline-color: #749aec;
}

.gap-16 {
    gap: 1rem;
}

.style_exception1 {
    color: black;
}`, "",{"version":3,"sources":["webpack://./src/components/admin/CreatePanel.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,WAAW;AACf;;AAEA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,wBAAwB;AAC5B;;AAEA;IACI,wBAAwB;IACxB,uDAAuD;IACvD;;WAEO;IACP,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,sBAAsB;IACtB,4BAA4B;IAC5B,wEAAwE;AAC5E;;AAEA;;IAEI,yCAAyC;AAC7C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".modal__form>*+* {\n    margin-top: 0.625rem;\n}\n\n.modal__form__group {\n    display: flex;\n    flex-direction: column;\n    width: 100%;\n}\n\n.modal__form__label {\n    margin-bottom: 0.25rem;\n    font-size: 0.875rem;\n    font-family: InterMedium;\n}\n\n.modal__form__input {\n    padding: 0.25rem 0.75rem;\n    border: var(--border-radius-lg) solid var(--border-clr);\n    border-radius:\n        /*0.375rem*/\n        4px;\n    font-size: 1rem;\n    line-height: 1.5;\n    color: #4a5568;\n    background-color: #fff;\n    background-clip: padding-box;\n    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;\n}\n\n.modal__form__input:hover,\n.modal__form__input:focus {\n    border: 1px solid var(--border-clr-hover);\n}\n\n.modal__form__input:focus-visible {\n    outline-color: #749aec;\n}\n\n.gap-16 {\n    gap: 1rem;\n}\n\n.style_exception1 {\n    color: black;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
